import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import { Row, Col } from "react-bootstrap"
import styles from "./benefits.module.css"

const Benefits = ({ title, items }) => {
  const { checkIcon } = useStaticQuery(query)

  return (
    <Row className={styles.bannerContent}>
      <Col className={styles.bannerColumn}>
        <Row className={styles.bannerHeader}>
          <Col>
            <h3 className={styles.heading}>{title}</h3>
          </Col>
        </Row>
        {items.map((f, idx) => {
          return (
            <Row key={idx} className={styles.memberBenefits}>
              <Col>
                {/*<p className={styles.description}>*/}
                  <Img
                    className={styles.checkIcon}
                    fluid={checkIcon.childImageSharp.fluid}
                  />
                  {f}
                {/*</p>*/}
              </Col>
            </Row>
          )
        })}
      </Col>
    </Row>
  )
}

export default Benefits

const query = graphql`
  query {
    checkIcon: file(relativePath: { eq: "icons/check-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
