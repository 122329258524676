import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import { Row, Col, Button } from "react-bootstrap"

import Layout from "../components/layout"
import Benefits from "../components/benefits"

import globalStyle from "../styles/global.module.css"
import styles from "./page.module.css"

const MommysHelper = ({ data }) => {
  const [content, setContent] = useState()
  useEffect(() => {
    if (data.allMarkdownRemark.edges.length > 0)
      setContent(data.allMarkdownRemark.edges.pop().node)
  }, [data])

  if (!content) return null

  return (
    <Layout className="site-content">
      <div className={styles.page}>
        <h1 className={globalStyle.entryTitle}>{content.frontmatter.title}</h1>
        <blockquote>"{content.frontmatter.quote}" <br></br><br></br> {content.frontmatter.quoteAuthor}</blockquote>
        <br></br>

        <Row>
          <Col>
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              autoPlay={true}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 3,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {data.gallery.edges.map(({ node: photo }) => (
                <div className={styles.carousel} key={photo.name}>
                  <Img fluid={photo.childImageSharp.fluid} />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12} xs={12}>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
          </Col>
          <Col md={6} sm={12} xs={12} className="order-md-last">
            <Benefits
              title={content.frontmatter.benefitsTitle}
              items={content.frontmatter.benefitsList}
            />

            <div className={styles.requestButton}>
              <Button
                className={[
                  globalStyle.btnPrimary,
                  globalStyle.submitButtonOrange,
                  styles.button,
                ]}
                href={content.frontmatter.buttonAction}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                {content.frontmatter.buttonTitle}
              </Button>
            </div>

          </Col>
        </Row>

      </div>
    </Layout>
  )
}

export default MommysHelper

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { id: { eq: "mommys-helper" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            buttonAction
            buttonTitle
            benefitsTitle
            benefitsList
            quote
            quoteAuthor
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          html
        }
      }
    }
    gallery: allFile(
      filter: { relativePath: { regex: "/mommys-helper/.*/" } }
    ) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
  }
`
